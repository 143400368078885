.ying-yang--background {
  background-color: #000000;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

}

.ying-yang--background {
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100vh;
}
