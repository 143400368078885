.home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.home--placemat {
  position: absolute;
  z-index: 1;
}

.home--birthday-input {
  position: absolute;
  z-index: 2;
}

.home--warn-birthday-skipped {
  position: absolute;
  z-index: 3;
}

.home--initial-instructions {
  position: absolute;
  z-index: 4;
}