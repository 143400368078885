.placemat--background-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.placemat--background-image {
  width: 100vw;
  height: 100vh;

  overflow: hidden;
  position: absolute;
  z-index: 1;

  img {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.placemat--background-video {
  width: 100vw;
  height: 100vh;
  z-index: 11;
  position: absolute;
  opacity: 0;

}
.placemat--videoPlayer-idle {
  animation: fadeIn ease 0.5s;
  animation-fill-mode: forwards;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
