.whereToNext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  z-index: 20;
  position: absolute;
  background-color: rgba(0,0,0,0.9);

  animation: fadein 1s;
  animation-delay: 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -moz-animation-delay:1s;
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -webkit-animation-delay:1s;
  -o-animation: fadein 1s; /* Opera */

  opacity: 0;
  -webkit-opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
    -webkit-opacity: 0;
  }

  100% {
    opacity: 1;
    -webkit-opacity: 1;
  }
}

.whereToNext--title {
  color: #AEAEAE;
  font-size: 80px;
  margin: 40px auto;
}

.whereToNext--links {
  color: #707070;
  font-size: 20px;
  margin: 20px auto;
  text-decoration: underline;
  cursor: pointer;

  a {
    color: #707070;
  }
}
