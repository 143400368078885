@import '../../vars';

.initial-instructions--container {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  animation: fadeIn ease 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.initial-instructions--text-container {
  width: 68%;
  text-align: center;
}

.initial-instructions--title {
  color: #aeaeae;
  font-size: 2em;
  padding-bottom: 80px;

  @include sm {
    font-size: 5em;
  }
}

.initial-instructions--subtitle {
  color: #f1f2f2;
  font-size: 1.5em;
  padding-bottom: 50px;

  @include sm {
    font-size: 2.5em;
  }
}

.initial-instructions--continue {
  color: #ffffff;
  font-size: 1.25em;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
