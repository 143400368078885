.constellation--cover {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    height: auto;
  }
}

.constellation--videoPlayer {
  width: 100vw;
  height: 100vh;
  z-index: 11;
  position: absolute;
  opacity: 0;
}

.constellation--videoPlayer-idle {
  animation: fadeIn ease .5s;
  animation-fill-mode: forwards;
}
.constellation--videoPlayer-fadeout {
  animation: fadeOut ease .5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
