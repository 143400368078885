@import '../../vars';

.finishedCover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  z-index: 20;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);

  animation: fadein 1s;
  animation-delay: 3s;
  -moz-animation: fadein 1s; /* Firefox */
  -moz-animation-delay: 3s;
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -webkit-animation-delay: 3s;
  -o-animation: fadein 1s; /* Opera */

  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.finishedCover--title {
  color: #aeaeae;
  font-size: 80px;
  margin: 40px auto;
  text-align: center;
}

.finishedCover--links {
  color: #707070;
  font-size: 20px;
  margin: 20px auto;
  text-decoration: underline;
  cursor: pointer;

  a {
    color: #707070;
  }
}

.finishedCover--footer {
  color: #707070;
  font-size: 20px;
  margin: 100px auto 20px;

  @include md {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: #707070;
  }
  .finishedCover--donate {
    margin-bottom: 40px;
    @include md {
      margin-right: 80px;
    }
  }
  .finishedCover--mailinglist {
    @include md {
      margin-left: 80px;
    }
  }

  .finishedCover--outside {
    position: absolute;
    left: -5000px;
  }
  .finishedCover--hidden {
    display: none;
  }
}
#mc_embed_signup_scroll {
  display: flex;
  flex-direction: row;

  input[type='email'] {
    margin-top: 5px;
    color: #000000;
    border: none;
    padding: 5px;
  }
  input[type='submit'] {
    margin: 5px 0 0 5px;
    color: #000000;
    border: none;
    background: #707070;
    box-shadow: none;
    border-radius: 0px;

    padding: 5px;
  }
}
