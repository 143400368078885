@import '../../vars';

.about--container {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  background-color: #000000;

  @include md {
    height: 100vh;
  }
}

.about--content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}
.about--content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #5a5a5a;
}

.about--content::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #1e1e1e;
}

.about--content {
  margin-top: 160px;
  padding-right: 10px;
  width: 90vw;
  height: auto;
  overflow: auto;

  h1 {
    font-size: 60px;
    font-weight: 300;
  }
  h2 {
    font-size: 26px;
    font-weight: 300;
  }
  section {
    font-weight: 300;
    font-size: 18px;
    line-height: 35px;
    margin: 30px 0;

    // display: flex;

    a {
      color: #5a5a5a;
      text-decoration: underline;

      &:hover {
        color: #ffffff;
      }
    }
  }

  @include md {
    margin-top: 40px;
    width: calc(100vw - 300px);
    height: calc(100vh - 200px);
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    h1 {
      font-size: 83px;
    }
    h2 {
      font-size: 41px;
    }
    section {
      font-size: 29px;
      line-height: 35px;
      margin: 30px 0;
    }
  }
}

.about--bio-wrapper {
  display: flex;
  margin: 20px 0;

  flex-direction: column;
  @include sm {
    flex-direction: row;
  }
}

.about--bio-image {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }

  @include sm {
    width: 25%;
  }
}
.about--bio-text {
  width: 100%;
  margin-left: 0;

  @include sm {
    width: calc(75% - 40px);
    margin-left: 40px;
  }
  a {
    color: white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.about--sponsors-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  flex-wrap: wrap;
  @include md {
    justify-content: center;
  }

  img {
    margin-right: 30px;
  }
}
