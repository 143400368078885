.card--background {
  background-color: white;
  width: 62vw;
  height: 57vh;
  max-width: 900px;
  max-height: 470px;
  display: flex;
  flex-direction: column;
}
.card--close {
  font-size: 40px;
  font-weight: 300;
  text-align: right;
  padding: 10px 30px 0 0;
  cursor: pointer;
}
.card--content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 40px;
  font-weight: 300;
  color: #333333;
  line-height: 60px;
  text-align: center;
  a {
    color: #333333;
    text-decoration: none;
    border-bottom: 1px solid #333333;

    &:hover {
      border-bottom: 2px solid #333333;
    }
  }
}
