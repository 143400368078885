.background--wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.background--image {
  background-color: #333333;
  width: 100vw;
  height: 100vh;

  overflow: hidden;
  position: absolute;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.background--content {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
}
