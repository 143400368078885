@import '../../vars';

.birthday-input {
  background-color: #dad7c9;
  min-height: 200px;
  padding: 32px;
  animation: fadeIn ease 1s;
  opacity: 0;
  animation-fill-mode: forwards;

  @include sm {
    padding: 32px 80px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.birthday-input--title {
  color: #707070;
  font-size: 1.5em;

  @include sm {
    font-size: 2.5em;
  }
}

.birthday-input--container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
}

.birthday-input--form-container {
  display: flex;
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }
}

.birthday-input--container-box {
  width: 100%;
  margin: 25px auto 0;
  padding: 0;

  &:last-child {
    padding: 0;
  }

  @include sm {
    width: 96px;
    margin: 25px 0 0;
    padding: 0 40px 0 0;
  }

  .birthday-input--legend {
    display: none;
    @include sm {
      display: block;
      color: #707070;
      font-size: 1.25em;
      text-align: center;
    }
  }
}

.birthday-input--input {
  width: calc(100% - 8px);
  height: 32px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1em;
  text-align: center;
  @include sm {
    font-size: 1.25em;
    height: 66px;
    margin: 0 0 10px 0;
  }
}

.birthday-input--input-prestine {
  border: 4px solid #ffffff;
}

.birthday-input--input-error {
  border: 4px solid #ff0000;
}

.birthday-input--enter {
  width: 100%;
  height: 36px;
  padding: 0;
  border: 0;
  font-size: 1.25em;
  background-color: #707070;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #8d8d8d;
  }

  @include sm {
    height: 66px;
  }
}

.birthday-input--skip {
  display: flex;
  justify-self: center;
  width: fit-content;
  align-self: center;
  color: #707070;
  font-size: 1em;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 32px;
  &:hover {
    text-decoration: none;
  }

  @include sm {
    justify-self: right;
    align-self: flex-end;
    margin-tio: 0;
  }
}
