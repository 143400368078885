.stary-background--wrapper {
  width: 100vw;
  height: 100vh;
}

.constellation-geometry--click-over {
    width: 100vw;
    height: 100vh;
    z-index: 10;
    position: absolute;
    background-color: black;
}
