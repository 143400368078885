@import '../../vars';

.skip-birthday--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;

  animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.skip-birthday--text-container {
  width: 90vw;
  min-height: 50vh;
  background-color: #ffffff;
  border: 1px solid #707070;
  text-align: center;
  padding: 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


    @include sm {
    width: 60vw;
  }
}

.skip-birthday--title {
  color: #707070;
  font-size: 1.5em;
  padding-bottom: 80px;
  width: 75%;

  @include sm {
    font-size: 2.5em;
  }
}

.skip-birthday--subtitle {
  color: #707070;
  font-size: 1em;
  padding-bottom: 50px;
  width: 75%;
  @include sm {
    font-size: 1.25em;
  }
}

.skip-birthday--buttons-container {
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: center;

  @include sm {
    flex-direction: row;
    width: 75%;
    justify-content: space-between;
  }
}

.skip-birthday--buttons-wrapper {
  display: flex;
  flex-direction: column;
}

.skip-birthday--button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #707070;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  height: 66px;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: #8d8d8d;
  }

  @include sm {
    font-size: 1.25em;
    width: 96px;
    height: 66px;
  }
}

.skip-birthday--button-subtitle {
  font-size: 0.75em;
  color: #707070;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 16px;

  @include md {
    font-size: 1em;
    margin-top: 16px;
    margin-top: 16px;
  }
}
