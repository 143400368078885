.layout {
  position: relative;
}

.layout--header {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
}

.layout--yingyang {
  width: 150px;
  height: 150px;
  position: absolute;
  right: 0;
  top: 0;
}

.layout--container {
  position: absolute;
  top: 0;
  z-index: 0;
}
