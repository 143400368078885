.header {
  margin: 35px 0 0 35px;
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      padding: 0 20px;
      text-transform: uppercase;
    }
  }
}

.header--link {
  color: #999999;
  text-decoration: none;

  &:hover {
    color: #cccccc;
  }
}

.header--link-selected {
  color: #333333;
  text-decoration: none;
}
