.yingyang-details--container {
  background-color: #000000;
  width: 100vw;
  min-height: 100vh;
  height: auto;
}

.yingyang-details--play {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
