@import '../../vars';

.get-chrome--footnote {
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  color: #333333;
  text-align: center;
  margin: 10px auto 0;
  width: 100vw;
  max-width: auto;
  a {
    color: #333333;
    text-decoration: none;
    border-bottom: 1px solid #333333;

    &:hover {
      border-bottom: 2px solid #333333;
    }
  }

  @include md {
    font-size: 30px;
    width: 60vw;
    max-width: 1024px;
  }
}

.get-chrome--popover-trigger {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
}

.get-chrome--popover-close {
  text-align: right;

  & img {
    cursor: pointer;
    width: 24px;
    height: auto;
  }
}

.get-chrome--popover-content {
  width: 60vw;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 5px 5px 10px #888888;
  a {
    color: #333333;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  ol {
    list-style: square;
    margin-left: 20px;
    li {
      padding: 5px 0;
    }
  }
}
