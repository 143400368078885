.zodiac-details--wrapper {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  padding: 140px 20px 50px 0;
  max-width: 1640px;
  width: 100%;
  margin: 0 auto;
}
.zodiac-details--icon {
  width: 15%;

  & img {
    width: 90%;
    height: auto;
    margin: 0 5%;
  }
}

.zodiac-details--listing {
  padding: 0 20px 0 50px;
  width: 85%;
}

.zodiac-details--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.zodiac-details--title {
  font-size: 30px;
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 50px;
}

.zodiac-details--specs {
  display: flex;
  flex-direction: row;
}

.zodiac-details--spec-column {
  width: 60%;
  &:first-child {
    width: 40%;
  }
  .zodiac-details--spec-line-left {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    & div {
      font-size: 22px;
      font-family: 'Raleway', sans-serif;
      &:first-child {
        width: 65%;
      }
    }
  }

  .zodiac-details--spec-line-right {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    & div {
      font-size: 22px;
      font-family: 'Raleway', sans-serif;
      &:first-child {
        width: 30%;
      }
    }
  }


}


.zodiac-details--signs-title {
  font-size: 22px;
  font-family: 'Raleway', sans-serif;
  margin-top: 80px;
  text-transform: uppercase;
}

.zodiac-details--footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;

  font-size: 22px;
  font-family: 'Raleway', sans-serif;
}

.zodiac-details--footer-wrapper {
  width: 20%;
}

.zodiac-details--footer-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  text-align: left;
}
