@import '../../vars';

.elements-circle--wrapper {
  position: relative;
  width: 600px;
  height: 600px;
  top: 30px;

  @include xl {
    width: 700px;
    height: 700px;
  }
}

.elements-circle--rings {
  position: absolute;
  z-index: 1;

  & img {
    width: 100%;
    height: auto;
  }
}

.title {
  position: absolute;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.elements-circle--element {
  .ying {
    position: absolute;
    z-index: 4;
    top: 0;
    left: -50px;
    width: 130px;
    height: 160px;
    background-color: rgba(255, 0, 0, 0);
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title {
      color: white;
      text-decoration: none;
    }
  }
  .yang {
    position: absolute;
    z-index: 4;
    top: 0px;
    left: 80px;
    width: 130px;
    height: 160px;
    background-color: rgba(0, 255, 0, 0);
    cursor: pointer;
    display: flex;
      justify-content: flex-end;
    align-items: center;
    .title {
      color: white;
      text-decoration: none;
    }
  }
}
.up {
  position: absolute;
  z-index: 1;
  width: 262px;
  height: 140px;

  & img {
    padding-left: 70px;
    cursor: pointer;
  }
}

.elements-circle--central-fire {
  position: absolute;
  z-index: 3;
  top: -80px;
  left: 185px;
  width: 161px;
  height: 161px;

  @include xl {
    top: -90px;
    left: 262px;
  }
}

.elements-circle--central-earth {
  position: absolute;
  z-index: 3;
  top: 130px;
  left: 460px;
  width: 161px;
  height: 161px;

  @include xl {
    top: 160px;
    left: 620px;
  }
}

.elements-circle--central-metal {
  position: absolute;
  z-index: 3;
  top: 470px;
  left: 340px;
  width: 161px;
  height: 161px;

  @include xl {
    top: 550px;
    left: 470px;
  }
}

.elements-circle--central-water {
  position: absolute;
  z-index: 3;
  top: 470px;
  left: -20px;
  width: 161px;
  height: 161px;

  @include xl {
    top: 540px;
    left: 50px;
  }
}

.elements-circle--central-wood {
  position: absolute;
  z-index: 3;
  top: 130px;
  left: -120px;
  width: 161px;
  height: 161px;

  @include xl {
    top: 160px;
    left: -60px;
  }
}

.elements-circle--central-logo {
  position: absolute;
  z-index: 2;
  top: 135px;
  left: 140px;

  @include xl {
    top: 185px;
    left: 195px;
  }

  .ying {
    position: absolute;
    top: 30px;
    left: 0px;
    width: 160px;
    height: 260px;
    display: flex;
    padding: 110px 0 0 90px;
    z-index: 4;
    text-transform: uppercase;
    cursor: pointer;
    .title {
      color: #000000;
      text-decoration: none;
    }
  }

  .yang {
    position: absolute;
    top: 30px;
    left: 160px;
    width: 160px;
    height: 260px;
    display: flex;
    z-index: 4;
    padding: 140px 0 0 30px;
    text-transform: uppercase;
    cursor: pointer;
    .title {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
.elements-circle--element-image-wrapper {
  position: relative;
  width: 160px;
  height: 160px;
}
.elements-circle--central-logo-image-wrapper {
  position: relative;
  width: 320px;
  height: 320px;
}

.elements-circle--image-up {
  position: absolute;
  z-index: 1;
}

.elements-circle--image-yin {
  position: absolute;
  z-index: 2;
}

.elements-circle--image-yang {
  position: absolute;
  z-index: 2;
}

.elements-circle--image-hidden {
  visibility: hidden;
}

.elements-circle--image-visible {
  visibility: visible;
}
