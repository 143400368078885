@import '../../vars';

.intro--container {
  color: #333333;
  text-align: center;
  h1 {
    font-size: 60px;
    font-weight: 300;
  }
  h2 {
    font-size: 30px;
    font-weight: 300;
  }

  h3 {
    font-size: 26px;
    font-weight: 300;
    margin-top: 40px;
  }

  h4 {
    font-size: 20px;
    font-weight: 300;
    margin: 0;
  }

  p {
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  @include md {
    h1 {
      font-size: 100px;
    }
    h2 {
      font-size: 50px;
    }

    h3 {
      font-size: 45px;
      margin-top: 40px;
    }

    h4 {
      font-size: 35px;
    }

    p {
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
}

.intro--password-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  width: 100%;

  @include md {
    flex-direction: row;
    width: 520px;
  }
}
.intro--password-container {
  width: 80%;
  margin: 0 auto;
  @include md {
    width: 407px;
    margin-left: 100px;
  }
}
.intro--password-enter {
  font-size: 30px;
  font-weight: 300;
  color: #333333;
  margin: 60px auto;

  // border-bottom: 1px solid #333333;
  cursor: pointer;

  @include md {
    // padding-top: 60px;
    // margin-left: 20px;

    margin: 120px auto 60px;
  }
}

.intro--password {
  width: calc(100% - 20px);
  height: 46px;
  margin: 0 0 10px 0;
  padding: 10px;
  border: 0;
  font-size: 1.25em;
}

.intro--password-prestine {
  border: 4px solid #ffffff;
}

.intro--password-error {
  border: 4px solid #ff0000;
}

.intro--video-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 50vw;
  margin: 40px auto 0;

  font-size: 24px;
  line-height: 40px;
  font-weight: 300;
  color: #333333;
  a {
    color: #333333;
    text-decoration: none;
    border-bottom: 1px solid #333333;

    &:hover {
      border-bottom: 2px solid #333333;
    }
  }

  @include md {
    font-size: 30px;
    line-height: 60px;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
  }
}

.intro--footnote {
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  color: #333333;
  text-align: center;
  margin: 10px auto 20px;
  width: 100vw;
  max-width: auto;
  a {
    color: #333333;
    text-decoration: none;
    border-bottom: 1px solid #333333;

    &:hover {
      border-bottom: 2px solid #333333;
    }
  }

  @include md {
    font-size: 30px;
    width: 60vw;
    max-width: 1024px;
  }
}
