.element-details--wrapper {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  padding: 100px 0 50px 0;
  max-width: 1640px;
  width: 100%;
  margin: 0 auto;
}
.element-details--icon {
  width: 15%;

  & img {
    width: 90%;
    height: auto;
    margin: 0 5%;
  }
}

.element-details--listing {
  padding: 0 20px 0 50px;
  width: 85%;
}

.element-details--title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.element-details--title {
  font-size: 30px;
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 50px;
}

.element-details--specs {
  display: flex;
  flex-direction: row;
}

.element-details--spec-column {
  width: 36%;
  &:first-child {
    width: 24%;
  }
  &:last-child {
    width: 40%;
  }
  .element-details--spec-line {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    & div {
      font-size: 22px;
      font-family: 'Raleway', sans-serif;
      &:first-child {
        width: 220px;
      }
    }
  }
  &:first-child {
    .element-details--spec-line {
      & div {
        &:first-child {
          width: 120px;
        }
      }
    }
  }

  &:last-child {
    .element-details--spec-line {
      & div {
        &:first-child {
          width: 240px;
        }
      }
    }
  }
}

.element-details--description {
  width: 100%;
  font-size: 25px;
  font-family: 'Raleway', sans-serif;
  margin-top: 80px;
  line-height: 30px;
}

.element-details--signs-title {
  width: 100%;
  font-size: 25px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.element-details--footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.element-details--signs-wrapper {
  display: flex;
  flex-direction: row;
  width: calc(100% - 320px);
}

.element-details--sign-column {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 20px;
  font-size: 25px;
  font-family: 'Raleway', sans-serif;
  & img {
    width: 110%;
    height: auto;
    margin: 0 5%;
  }
}
.element-details--sign-button {
  width: 320px;
  height: 120px;
  font-size: 26px;
  background-color: #A1A1A1;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  transition: .3s all ease;
  &:hover {
    background-color: #909090;
  }
}


.element-details--close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 60px;
}
.element-details--birthday-input {
  position: fixed;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.element-details--birthday-input-wrapper {
  background-color: #ffffff;
  width: 50vw;
  min-height: 50vh;
  padding: 20px 20px 80px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    width: 60%;
    font-size: 40px;
    color: #707070;
    font-family: "Quicksand", sans-serif;
    font-weight: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  p {
    width: 70%;
    font-size: 20px;
    color: #000000;
    font-family: "Quicksand", sans-serif;
    font-weight: normal;
    text-align: center;
    margin-bottom: 30px;
  }

}
